@font-face {
  font-family: "Blair";
  src: local("Blair"), url("./media/Blaimim.ttf") format("truetype");
}
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;1,400&family=Roboto&display=swap");
$mainRed: #b80f0a;
$champagne: #f5e2c8;
$aliceBlue: #e9f1f7;

$uniFont: 5rem;

.simple-landing {
  text-align: center;
  height: 100vh !important;

  display: flex;
  flex-direction: column;
  align-items: center;

  .logo-landing {
    margin: auto;

    .logo-svg {
      width: 400px;
      max-width: 65vw;
    }

    a {
      color: black !important;

      border: 1px solid black;
      font-weight: 500;
      font-size: 1.5rem;

      text-decoration: none !important;

      &:hover {
        background-color: $mainRed;
        color: white !important;
        border-color: transparent;

        .linkedin-logo {
          filter: grayscale(100%) contrast(0%) brightness(2);
        }
      }
    }

    .linkedin-logo {
      height: 2.1rem;
      transform: translateY(-12.5%);

      filter: brightness(0.1);
    }
  }
}

.m-hide {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.d-hide {
  @media (min-width: 769px) {
    display: none !important;
  }
}

.transition-all {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

body {
  background: white;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
  display: none;
}
.full-body {
  overflow-x: hidden !important;
}

.tbird-font {
  font-family: "Blair";
}

.playfair {
  font-family: "Playfair Display", serif;
}

.roboto {
  font-family: "Roboto", "sans-serif" !important;
}

.weight-400 {
  font-weight: 400;
}

.weight-500 {
  font-weight: 500;
}

.weight-600 {
  font-weight: 600;
}

.red {
  color: $mainRed;

  &:hover {
    text-decoration: none !important;
    color: $mainRed !important;
  }
}

.red-a {
  color: $mainRed !important;
}

.navbar {
  position: fixed;
  z-index: 99;
  background-color: white;
  .nav-logo {
    img {
      max-width: 40px;
    }

    a {
      cursor: pointer;
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      -o-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
      &.active {
        opacity: 0;
      }
      &:hover img {
        filter: brightness(1.5);
      }
    }
  }

  .w-40-d {
    width: 40%;

    text-align: center;
    align-items: center;

    a {
      cursor: pointer;

      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      -o-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;

      &:hover {
        color: $mainRed;
        text-decoration: none;
      }
      &.active {
        color: $mainRed !important;
      }
    }
  }

  @media (max-width: 768px) {
    background-color: transparent;
    margin: 0 !important;
    padding: 0 !important;

    .d-hide {
      position: relative;
    }

    .burger-box {
      background-color: white;
      border-radius: 0.5em;
      border-style: solid;
      border-color: $mainRed;
      border-width: 1px;

      position: absolute;
      right: 0;
      top: 0;
    }

    .burger-buttons {
      background-color: rgba(255, 255, 255, 0.95);
      font-size: 1.3rem;

      .active {
        color: $mainRed;
      }
    }
  }
}

section {
  width: 100vw;
}

.dots {
  max-width: 10vw;
  position: absolute;

  right: 5rem;
  bottom: -7rem;
}

.home {
  flex-direction: row;
  align-items: center;
  min-height: 100vh;

  .home-text {
    width: 60%;
    display: flex;
    align-items: center;

    .head-text {
      h1 {
        font-size: 5rem;
      }
      h2 {
        font-size: 4rem;
        letter-spacing: 0.1rem;
      }

      .playfair {
        font-size: 2rem;
        text-decoration: none !important;
        background-color: white;
      }
    }
  }

  .home-img {
    width: 40%;

    img {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    margin-top: 5rem;
    min-height: 80vh;
    .home-text {
      width: 100%;
      .head-text {
        h1 {
          font-size: 3rem;
        }
        h2 {
          font-size: 2.4rem;
        }
        .playfair {
          font-size: 1.2rem;
          margin-bottom: 5rem;
        }
      }
    }
    .home-img {
      width: 100%;
    }
  }
}

.divider {
  max-width: 8vw;
}

.about {
  min-height: unset !important;
  span {
    font-family: "Playfair Display", serif !important;
  }
  p {
    font-size: 1.5rem;
    font-family: "Playfair Display", serif !important;
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.2rem;
    }
  }
}

.zero-margin {
  margin: 0;
}

.team {
  .profile-pic {
    border-radius: 1em;
    overflow: hidden;
  }

  @media (max-width: 768px) {
    .profile-image {
      width: 100% !important;
      justify-content: center;
      padding: 0 !important;

      .profile-pic {
        width: 85% !important;
        margin: auto;
      }
      img {
        width: 100% !important;
        margin: auto;
      }
    }
    .profile-desc {
      width: 100% !important;
      .name-sec {
        justify-content: center;
        margin-top: 2rem;
        margin-bottom: 1rem;
        h3 {
          a {
            margin: 0 !important;
          }
        }
      }
      .bio-expand {
        text-align: center;
        button {
          background-color: transparent;
          border: none;
          text-decoration: underline;
          width: unset;
        }
      }
    }
  }
}

.linkedin-logo {
  height: 1.5rem;
  transform: translateY(-12.5%);

  filter: brightness(0.1);

  &:hover {
    filter: brightness(0.6);
  }
}

.portfolio {
  min-height: unset !important;

  .img-container {
    border-radius: 1rem;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    overflow-x: hidden;
  }
  .col-auto {
    width: 20% !important;
    margin: 0 !important;
    padding: 1rem;
    text-align: center;
  }

  .company-link {
    font-family: "Roboto", sans-serif;
  }
  .company-link:hover .img-container {
    img {
      filter: blur(2px);
    }
  }

  @media (max-width: 768px) {
    .col-auto {
      width: calc(100% / 3) !important;
    }
  }
}

.footer {
  a {
    color: black;

    &:hover {
      color: $mainRed;
      text-decoration: none;

      .linkedin-logo {
        filter: brightness(0.7);
      }
    }
  }
}
